<template>
  <div class="d-flex flex-column justify-content-between">
    <b-overlay
      :show="!shortenText && !isHistory"
      rounded="sm"
      no-fade
      variant="transparent"
      :opacity="0.8"
    >
      <b-form-textarea
        v-model="shortenText"
        class="font-weight-bolder text-uppercase"
        rows="16"
        max-rows="40"
        style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif;"
      />
    </b-overlay>

    <!-- <div
      v-if="['1G'].includes(reservationsData.source)"
      class="d-flex-between m-1"
    >
      <BButton
        v-b-tooltip.hover.v-info
        :title="$t(`reservation.${isHistory ? 'history' : 'shortenBooking'}.reloadButtonTooltip`)"
        variant="outline-primary"
        class="px-1"
        pill
        @click="handleReload"
      >
        <div class="d-flex-center">
          <feather-icon
            icon="RotateCwIcon"
            size="16"
            :class="`mr-50 ${loading ? 'spinner-1s' : ''}`"
          />
          <span>
            Làm mới
          </span>
        </div>
      </BButton>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-yellow px-1"
        pill
        :disabled="!shortenText || disabledCopy"
        @click="copyShortenText"
      >
        {{ $t('reservation.copy') }}
      </b-button>
    </div> -->
  </div>
</template>

<script>
import {
  BOverlay, BFormTextarea, VBTooltip,
  // BButton,
} from 'bootstrap-vue'
import {
  // onMounted,
  toRefs, ref,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { useClipboard } from '@vueuse/core'

export default {
  components: {
    BOverlay,
    BFormTextarea,
    // BButton,
  },
  directives: {
    Ripple, 'b-tooltip': VBTooltip,
  },
  props: {
    shortenText: {
      type: String,
      default: () => '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    reservationsData: {
      type: Object,
      default: () => {},
    },
    isHistory: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { copy } = useClipboard()
    const shortenText = toRefs(props).shortenText
    const disabledCopy = ref(false)

    function copyShortenText() {
      disabledCopy.value = true
      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      copy(shortenText.value)
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }

    function handleReload() {
      emit('get-shorten-booking-handle')
    }

    return {
      copyShortenText,
      disabledCopy,
      handleReload,
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

  <style lang="scss" scoped>
  .payment-div {
    position: sticky;
    bottom: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    border-radius: 8px;
  }

  .min-h-unset {
    min-height: unset;
  }
  .rotate-180 {
    transition: all 0.25s ease-out;
    transform: rotate(-180deg);
  }
  </style>
