var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column justify-content-between"
  }, [_c('b-overlay', {
    attrs: {
      "show": !_vm.shortenText && !_vm.isHistory,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.8
    }
  }, [_c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif"
    },
    attrs: {
      "rows": "16",
      "max-rows": "40"
    },
    model: {
      value: _vm.shortenText,
      callback: function callback($$v) {
        _vm.shortenText = $$v;
      },
      expression: "shortenText"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }